<template>
  <div>
    <div class="banner-wrapper">
      <!-- 轮播图 -->
      <banner :slides="banners"></banner>
    </div>
    <div class="self_box">
      <div class="self_list flex-container flx flex-pack-justify">
        <div class="" @click="$router.push({ name: 'UserBind' })">
          <img src="@/assets/imgs/户号绑定.png" alt="户号绑定">
        </div>
        <div class="" @click="$router.push({ name: 'AccountInfo'})">
          <img src="@/assets/imgs/账户信息.png" alt="账户信息">
        </div>

        <div class=""
             @click="checkGuestNo() && $router.push({ name: 'PayRecord',query:{bindId: bindId}})">
          <img src="@/assets/imgs/缴费记录.png" alt="缴费明细">
        </div>

        <div class="" @click="checkGuestNo() && $router.push({ name: 'WaterRecord',query:{bindId: bindId}})">
          <img src="@/assets/imgs/用水明细.png" alt="用水明细">
        </div>
        <div class="" @click="$router.push({ name: 'WaterPayment' })">
          <img src="@/assets/imgs/户号管理.png" alt="户号管理">
        </div>
        <div class="" @click="checkGuestNo() && $router.push({ name: 'pay',query:{bindId:bindId}})">
          <img src="@/assets/imgs/户号缴费.png" alt="户号缴费">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ajax from '@/api'
import Banner from '../Home/Banner'
import banner1 from '@/assets/imgs/IMG_7522.png'
import banner2 from '@/assets/imgs/IMG_7521.png'
import {getUrlParam} from '@/utils/tools'
import {tokenCache, userIdCache} from '@/assets/js/cache'

export default {
  name: 'OwnCenter',
  components: {Banner},
  data() {
    return {
      bindId: null,
      wxUserList: [],
      banners: [],
    }
  },
  mounted() {
    this.getBannerList()
  },
  watch: {
    userToken(newToken) {

      newToken && this.getCurBindId() && this.getWxUserInfo()
    }
  },
  created() {
    tokenCache.deleteCache()
    tokenCache.setCache(getUrlParam('token'))
    this.getWxUserInfo()
    this.getCurBindId()
  },
  methods: {
    getBannerList(){
      ajax.getWxp().then((res) => {
        res.data.forEach((value, index) => {
          this.banners.push({
            id: index,
            img: value
          });
        });
      })
    },
    // 检查户号
    checkGuestNo() {
      if (this.wxUserList.length === 0) {
        this.$dialog.alert({message: '请先绑定户号'}).then(() => {
          this.$router.push({name: 'UserBind'})
        })
        return false
      }
      return true
    },
    // 获取微信用户信息
    async getWxUserInfo() {
      await ajax.getWxUserInfo().then((res) => {
        userIdCache.setCache(res.data.userId)
      })
    },
    getCurBindId() {
      ajax.getAllAccount().then((res) => {
        this.wxUserList = res.data
        if (res.data.length !== 0){
          this.bindId = res.data[0].bindId
        }else {
          this.bindId = null
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style.scss';

.banner-wrapper {
  position: relative;

  > img {
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
    border: 2px solid #8dc4fe;
    position: absolute;
    top: 50%;
    left: 15%;
    transform: translate(-50%, -50%);
  }

  > span {
    position: absolute;
    left: calc(15% + 30px);
    top: 50%;
    transform: translate(0, -50%);
  }
}
</style>
